import React, { Suspense }  from 'react';
import {Outlet} from 'react-router-dom';
import {CircularProgress, makeStyles} from '@material-ui/core';
import withAppUserCheckRequests from "../components/ReferencedComponents/withAppUserCheckRequests";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        width: '100%'
    },
    wrapper: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        paddingTop: 56,
        [theme.breakpoints.up('sm')]: {
            paddingTop: 64,
        },
        [theme.breakpoints.up('lg')]: {
            paddingLeft: 256
        }
    },
    contentContainer: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden'
    },
    content: {
        flex: '1 1 auto',
        height: '100%',
        overflow: 'auto'
    }
}));

const DashboardLayout = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.contentContainer}>
                <div className={classes.content}>
                    <Suspense fallback={<CircularProgress />}>
                        <Outlet/>
                    </Suspense>
                </div>
            </div>
        </div>
    );
};

export default withAppUserCheckRequests(DashboardLayout);