import axios from 'axios';
import AuthHeader from './AuthHeader';
import moment from 'moment';
import 'moment/min/locales'
import 'moment-timezone';
import AuthService from './AuthService';

class UserService {

    _afterFetch(user) {
        moment.locale(AuthService.getLanguage());
        user.createdDate = user.createdDate && moment.utc(user.createdDate).local();
        user.modifiedDate = user.modifiedDate && moment.utc(user.modifiedDate).local();
        user.userDetail.birthdate = user.userDetail.birthdate && moment.utc(user.userDetail.birthdate).local();
        //user.userDetail.timezone = user.userDetail.timezone && JSON.parse(user.userDetail.timezone);
    }

    _beforePost(user) {
        delete user.avatar;
        user.createdDate && delete user.createdDate; 
        user.modifiedDate && delete user.modifiedDate;
        user.userDetail.birthdate = user.userDetail.birthdate && moment(user.userDetail.birthdate).utc().format();
        //user.userDetail.timezone = user.userDetail.timezone && JSON.stringify(user.userDetail.timezone);
    }

    loadUsers(search, page, limit, sortBy, sortDir) {
        return axios.get(process.env.REACT_APP_PINGBIT_ADMIN_API_ENDPOINT + `/user/all?search=${search}&page=${page}&limit=${limit}&sortBy=${sortBy}&sortDir=${sortDir}`, { headers: AuthHeader() })
            .then(res => {
                res.data.message.pageImpl.content.forEach(u => this._afterFetch(u));
                return res;
            })
    }

    searchUser(value, limit) {
        return axios.get(process.env.REACT_APP_PINGBIT_ADMIN_API_ENDPOINT + `/user/search?value=${value}&limit=${limit}`, { headers: AuthHeader() });
    }

    /*
    loadUsers(page, limit, sortBy, sortDir) {
        return axios.get(process.env.REACT_APP_PINGBIT_ADMIN_API_ENDPOINT + `/user/all?page=${page}&limit=${limit}&sortBy=${sortBy}&sortDir=${sortDir}`, { headers: AuthHeader() })
                
    }
    */

    getUserInfo(id = '') {
        return axios.get(process.env.REACT_APP_PINGBIT_ADMIN_API_ENDPOINT + `/user/${id}`, {headers: AuthHeader() })
                .then(res => {
                    this._afterFetch(res.data.message.data)
                    return res;
                });
    }

    saveOrUpdateUser(id, user) {
        this._beforePost(user);
        if (!id || id === null)
            return axios.post(process.env.REACT_APP_PINGBIT_ADMIN_API_ENDPOINT + `/user/`, user, {headers: AuthHeader() });
        else
            return axios.put(process.env.REACT_APP_PINGBIT_ADMIN_API_ENDPOINT + `/user/${id}`, user, {headers: AuthHeader() });
        /*
        const delay = t => new Promise(resolve => setTimeout(resolve, t));
        console.log(userId, user);
        return delay(3000);
        */
    }

    deleteUser(id) {
        return axios.delete(process.env.REACT_APP_PINGBIT_ADMIN_API_ENDPOINT + `/user/${id}`, { headers: AuthHeader() });
    }

    totalSizes(id) {
        return axios.get(process.env.REACT_APP_PINGBIT_ADMIN_API_ENDPOINT + `/user/configuration/${id}/totalsizes`, { headers: AuthHeader() });
    }

    saveTotalSize(id, totalsize) {
        let data = new FormData();
        data.append("totalsize", totalsize);
        return axios.post(process.env.REACT_APP_PINGBIT_ADMIN_API_ENDPOINT + `/user/configuration/${id}/totalsizes`, data, { headers: AuthHeader() });
    }

    /*
     * USER SERVICES
     */

    currentUser() {
        return axios.get(process.env.REACT_APP_PINGBIT_ADMIN_API_ENDPOINT + `/user/`, 
            {headers: AuthHeader() }).then(res => {
                this._afterFetch(res.data.message.data)
                return res;
            });
    }


    /*
     * DEFAULT SERVICES
     */

    fetch(search, page, limit, sortBy, sortDir) {
        return axios.get(process.env.REACT_APP_PINGBIT_ADMIN_API_ENDPOINT 
                        + `/users/all?search=${search}&page=${page}&limit=${limit}&sortBy=${sortBy}&sortDir=${sortDir}`, 
                        { headers: AuthHeader() })
                        .then(res => {
                            res.data.message.data.content.forEach(u => this._afterFetch(u));
                            return res;
                        });

    }

    get(id) {
        return axios.get(process.env.REACT_APP_PINGBIT_ADMIN_API_ENDPOINT 
                        + `/users/${id}`, 
                        {headers: AuthHeader() }).then(res => {
                            this._afterFetch(res.data.message.data)
                            return res;
                        });
    }

    persist(id, data) {
        let dataClone = Object.assign({}, data);
        this._beforePost(dataClone);

        let formData = new FormData();

        formData.append("file", data.avatar);
        formData.append('data', new Blob([JSON.stringify(dataClone)], { type: "application/json" }));

        if (!id || id === null)
            return axios.post(process.env.REACT_APP_PINGBIT_ADMIN_API_ENDPOINT 
                        + `/users/`, formData, 
                        {headers: AuthHeader() }).then(res => {
                            this._afterFetch(res.data.message.data)
                            return res;
                        });
        else
            return axios.put(process.env.REACT_APP_PINGBIT_ADMIN_API_ENDPOINT 
                        + `/users/${id}`, formData, 
                        {headers: AuthHeader() }).then(res => {
                            this._afterFetch(res.data.message.data)
                            return res;
                        });
    }

    delete(ids) {
        //let formData = new FormData();
        //formData.append("ids", new Blob([JSON.stringify(ids)], { type: "application/json" }));
        return axios.delete(process.env.REACT_APP_PINGBIT_ADMIN_API_ENDPOINT 
                        + `/users/`, 
                        { data: ids, headers: AuthHeader() });
    }

}

export default new UserService();