import './App.css';
import React, { Suspense } from 'react';
import {useRoutes} from 'react-router-dom';
import {ThemeProvider} from '@material-ui/core';
import GlobalStyles from './components/LayoutComponents/GlobalStyles';
import theme from './theme';
import routes from './routes';
import AuthService from './services/AuthService';

function App() {
    const routing = useRoutes(routes(AuthService.isLogged(), AuthService.getRole()));
    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles/>
            <Suspense fallback={<div>Yükleniyor ...</div>}>
                {routing}
            </Suspense>
        </ThemeProvider>
    );
}

export default App;
