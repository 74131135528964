import React from "react";
import { useSnackbar } from 'notistack';

const withMessage = (Component) => {
    const Wrapper = (props) => {

        const { enqueueSnackbar, closeSnackbar } = useSnackbar();

        const message = React.useCallback((message, messageType = 'success') => {
            if (typeof (message) === 'object')
                message = JSON.stringify(message);
            const key = enqueueSnackbar(message, { variant: messageType });
            return setTimeout(() => closeSnackbar(key), 4000);
        }, [enqueueSnackbar, closeSnackbar]);


        return (
            <Component
                message={message}
                {...props}
            />
        );
    };

    return Wrapper;
};

export default withMessage;