import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import AssessmentIcon from '@material-ui/icons/Assessment';
import {
    BarChart as BarChartIcon,
    Settings as SettingsIcon,
    Users as UsersIcon,
    LogOut as LogOutIcon,
    Calendar as MeetingIcon,
    Server as PacsIcon,
    Watch as DevicesIcon
} from 'react-feather';



export const items = [
    { href: `/${process.env.PUBLIC_URL}/app/dashboard`,              icon: BarChartIcon,     title: 'adminpanel.title',             roles: ["ROLE_ADMIN"] },
    { href: `/${process.env.PUBLIC_URL}/app/users/all`,              icon: UsersIcon,        title: 'users.title',                  roles: ["ROLE_ADMIN", "ROLE_DOCTOR", "ROLE_NURSE", "ROLE_USER", "ROLE_SUPERUSER"] },
    { href: `/${process.env.PUBLIC_URL}/app/relations/all`,          icon: UsersIcon,        title: 'relation.title',               roles: ["ROLE_ADMIN", "ROLE_DOCTOR", "ROLE_NURSE", "ROLE_SUPERUSER"] },
    { href: `/${process.env.PUBLIC_URL}/app/relationtypes/all`,      icon: UsersIcon,        title: 'relationtype.title',           roles: ["ROLE_ADMIN"] },
    { href: `/${process.env.PUBLIC_URL}/app/remindertypes/all`,      icon: AssessmentIcon,   title: 'remindertype.title',           roles: ["ROLE_ADMIN"] },
    { href: `/${process.env.PUBLIC_URL}/app/reminders/all`,          icon: UsersIcon,        title: 'reminders.title',              roles: ["ROLE_ADMIN", "ROLE_DOCTOR", "ROLE_NURSE", "ROLE_USER", "ROLE_SUPERUSER"] },
    { href: `/${process.env.PUBLIC_URL}/app/paymentinfos/all`,       icon: UsersIcon,        title: 'paymentinfo.title',            roles: ["ROLE_ADMIN", "ROLE_DOCTOR", "ROLE_NURSE"] },
    { href: `/${process.env.PUBLIC_URL}/app/advisors/all`,           icon: UsersIcon,        title: 'advisor.pageTitle',            roles: ["ROLE_ADMIN"] },
    { href: `/${process.env.PUBLIC_URL}/app/schedules/all`,          icon: MeetingIcon,      title: 'schedule.title',               roles: ["ROLE_ADMIN", "ROLE_DOCTOR", "ROLE_NURSE", "ROLE_USER", "ROLE_SUPERUSER"] },
    { href: `/${process.env.PUBLIC_URL}/app/devices/all`,            icon: DevicesIcon,      title: 'devices.title',                roles: ["ROLE_ADMIN", "ROLE_DOCTOR", "ROLE_NURSE", "ROLE_USER", "ROLE_SUPERUSER"] },
    { href: `/${process.env.PUBLIC_URL}/app/devicetypes/all`,        icon: DevicesIcon,      title: 'devicetype.title',             roles: ["ROLE_ADMIN"] },
    { href: `/${process.env.PUBLIC_URL}/app/userlocations/all`,      icon: DevicesIcon,      title: 'userlocations.title',          roles: ["ROLE_ADMIN", "ROLE_DOCTOR", "ROLE_NURSE", "ROLE_USER", "ROLE_SUPERUSER"] },
    { href: `/${process.env.PUBLIC_URL}/app/activities/all`,         icon: AssessmentIcon,   title: 'activity.title',               roles: ["ROLE_ADMIN"] },
    { href: `/${process.env.PUBLIC_URL}/app/useractivities/all`,     icon: AssessmentIcon,   title: 'useractivity.title',           roles: ["ROLE_ADMIN", "ROLE_DOCTOR", "ROLE_NURSE"] },
    { href: `/${process.env.PUBLIC_URL}/app/measurements/all`,       icon: AssessmentIcon,   title: 'measurement.title',            roles: ["ROLE_ADMIN", "ROLE_DOCTOR", "ROLE_NURSE", "ROLE_USER", "ROLE_SUPERUSER"] },
    { href: `/${process.env.PUBLIC_URL}/app/measurementtypes/all`,   icon: AssessmentIcon,   title: 'measurementtype.title',        roles: ["ROLE_ADMIN"] },
    { href: `/${process.env.PUBLIC_URL}/app/measurementdefinitions/all`,icon: AssessmentIcon,title: 'measurementdefinition.title',  roles: ["ROLE_ADMIN", "ROLE_DOCTOR", "ROLE_NURSE", "ROLE_USER", "ROLE_SUPERUSER"] },
    { href: `/${process.env.PUBLIC_URL}/app/forms/all`,              icon: AssessmentIcon,   title: 'form.title',                   roles: ["ROLE_ADMIN","ROLE_DOCTOR", "ROLE_NURSE"] },
    { href: `/${process.env.PUBLIC_URL}/app/userforms/all`,          icon: AssessmentIcon,   title: 'userform.title',               roles: ["ROLE_ADMIN","ROLE_DOCTOR", "ROLE_NURSE"] },
    { href: `/${process.env.PUBLIC_URL}/app/tickets/all`,            icon: AssessmentIcon,   title: 'ticket.title',                 roles: ["ROLE_ADMIN", "ROLE_DOCTOR", "ROLE_NURSE"] },
    { href: `/${process.env.PUBLIC_URL}/app/pacs`,                   icon: PacsIcon,         title: 'pacs.title',                   roles: ["ROLE_ADMIN"] },
    { href: `/${process.env.PUBLIC_URL}/app/settings`,               icon: SettingsIcon,     title: 'settings.title',               roles: ["ROLE_ADMIN"] },
    { href: `/${process.env.PUBLIC_URL}/app/notificationmessages/all`, icon: SettingsIcon,   title: 'notificationMessage.title',    roles: ["ROLE_ADMIN"] },
    { href: `/${process.env.PUBLIC_URL}/app/videoanalyzes/all`,      icon: VideoLabelIcon,   title: 'videoanalysis.title',          roles: ["ROLE_ADMIN", "ROLE_DOCTOR", "ROLE_NURSE"] },
    { href: `/${process.env.PUBLIC_URL}/app/videoanalysisrefs/all`,  icon: VideoLabelIcon,   title: 'videoanalysisref.title',       roles: ["ROLE_ADMIN"] },
    { href: `/${process.env.PUBLIC_URL}/app/organizations/all`,     icon: AssessmentIcon,    title: 'organization.title',           roles: ["ROLE_ADMIN"] },
    { href: `/${process.env.PUBLIC_URL}/app/logout`,                 icon: LogOutIcon,       title: 'exit.title',                   roles: ["ROLE_ADMIN", "ROLE_DOCTOR", "ROLE_NURSE", "ROLE_USER", "ROLE_SUPERUSER"] },
];