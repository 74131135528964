import axios from 'axios';

class AuthService {

    login(username, password, captchaResponse) {
        return axios.post(process.env.REACT_APP_PINGBIT_ADMIN_API_ENDPOINT + "/auth/signin", {
            username,
            password,
            captchaResponse
        })
            .then(response => {
                if (response.data.message.data) {

                    let user = response.data.message.data;

                    if (user.roles.includes('ROLE_ADMIN'))
                        user.defaultRole = 'ROLE_ADMIN';
                    else if (user.roles.includes('ROLE_SUPERUSER'))
                        user.defaultRole = 'ROLE_SUPERUSER';
                    else if (user.roles.includes('ROLE_DOCTOR'))
                        user.defaultRole = 'ROLE_DOCTOR';
                    else if (user.roles.includes('ROLE_NURSE'))
                        user.defaultRole = 'ROLE_NURSE';
                    else if (user.roles.includes('ROLE_USER'))
                        user.defaultRole = 'ROLE_USER';

                    localStorage.setItem("user", JSON.stringify(user));
                }
                return response.data;
            })
    }

    isLogged() {
        return localStorage.getItem("user") !== null;
    }

    logout() {
        localStorage.removeItem("user");
    }

    getCurrentUser() {
        return JSON.parse(localStorage.getItem('user'));
    }


    getLanguage() {
        let currentUser = this.getCurrentUser();
        if (currentUser)
            return currentUser.language.locale;
        return window.navigator.language;
    }

    getRole() {
        let currentUser = this.getCurrentUser();
        if (currentUser)
            return currentUser.defaultRole;
        return null;
    }
}

export default new AuthService();