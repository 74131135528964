import axios from 'axios';
import AuthHeader from './AuthHeader';
import AuthService from './AuthService';

class AvatarService {

    getAvatarUrl(id, type) {
        if (!id)
            return null;
        const user = AuthService.getCurrentUser();
        if (user && user.token)
           return process.env.REACT_APP_PINGBIT_ADMIN_API_ENDPOINT + `/${type}/avatar/${id}?access_token=${user.token}`;
        return process.env.REACT_APP_PINGBIT_ADMIN_API_ENDPOINT + `/${type}/avatar/${id}`;
    }

    getFileUrl(id, type) {
        if (!id)
            return null;
        const user = AuthService.getCurrentUser();
        if (user && user.token)
            return process.env.REACT_APP_PINGBIT_ADMIN_API_ENDPOINT + `/${type}/file/${id}?access_token=${user.token}`;
        return process.env.REACT_APP_PINGBIT_ADMIN_API_ENDPOINT + `/${type}/file/${id}`;
    }

    getAvatar(id) {
        return axios.get(process.env.REACT_APP_PINGBIT_ADMIN_API_ENDPOINT + `/avatar/${id}`, { headers: AuthHeader() });
    }

    setAvatar(id, avatar) {
        let data = new FormData();
        data.append("file", avatar);
        return axios.post(process.env.REACT_APP_PINGBIT_ADMIN_API_ENDPOINT + `/avatar/${id}`, data, { headers: AuthHeader() });
    }

}

export default new AvatarService();