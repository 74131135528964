import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    Avatar,
    Box,
    Divider,
    Drawer,
    Hidden,
    List,
    Typography,
    makeStyles
} from '@material-ui/core';

import DashboardNavItem from './DashboardNavItem';
import UserService from 'services/UserService';
import Skeleton from '@material-ui/lab/Skeleton';
import { Alert, AlertTitle } from '@material-ui/lab';
import AvatarService from 'services/AvatarService';
import {useTranslation} from "react-i18next";
import { items } from './MenuItems';
import AuthService from 'services/AuthService';


const useStyles = makeStyles(() => ({
    mobileDrawer: {
        width: 256
    },
    desktopDrawer: {
        width: 256,
        top: 64,
        height: 'calc(100% - 64px)'
    },
    avatar: {
        cursor: 'pointer',
        width: 64,
        height: 64,
        marginBottom: 5,
    }
}));

const DashboardNavBar = ({ onMobileClose, openMobile }) => {
    const classes = useStyles();
    const location = useLocation();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [currentUser, setCurrentUser] = useState({});
    const {t} = useTranslation(AuthService.getRole());


    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    useEffect(() => {
        setLoading(true);
        UserService.currentUser().then(res => {
            setCurrentUser(res.data.message.data);
        }, error => {
            const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            setError(resMessage);
        }).finally(() => setLoading(false));
    }, []);

    const content = (
        <Box height="100%" display="flex" flexDirection="column">
            <Box alignItems="center" display="flex" flexDirection="column" p={2}>
                {(loading ? 
                    (<>
                        <Skeleton animation="wave" variant="circle" width={60} height={60} />
                        <Skeleton animation="wave" height={10} width={100} />                    
                    </>)
                :
                (error !== null ? 
                    (<Alert severity="error" className={classes.errorAlert} variant="outlined">
                        <AlertTitle>{t('errors.loadingData')}</AlertTitle>
                        <strong>{error}</strong>
                    </Alert>)
                :
                    (currentUser && currentUser.id && (<>
                        <Avatar className={classes.avatar} component={RouterLink} src={AvatarService.getAvatarUrl(currentUser.id, "users")} to={`/app/users/${currentUser.id}`}/>
                        <Typography className={classes.name} color="textPrimary" variant="h5">
                            {`${(currentUser.userDetail && currentUser.userDetail.firstname) || ''} ${(currentUser.userDetail && currentUser.userDetail.lastname) || ''}`}
                        </Typography>
                        <Typography color="textSecondary" variant="body1"> {(currentUser.userDetail && currentUser.userDetail.city && currentUser.userDetail.city.name) || ''} </Typography>
                        <Typography color="textSecondary" variant="body2"> 
                                {currentUser.userDetail && currentUser.createdDate.format('LLL')} 
                        </Typography>
                        <Typography color="textSecondary" variant="body2"> 
                                {currentUser.userDetail && currentUser.userDetail.timezone.zoneName  + ' - ' + currentUser.userDetail.timezone.gmtOffsetName} 
                        </Typography>
                    </>))
                ))}   
            </Box>
            <Divider />
            <Box p={2}>
                <List>
                    {items
                    .filter(i => i.roles.includes(AuthService.getRole()))
                    .map(item => <DashboardNavItem href={item.href} key={item.title} title={t(item.title)} icon={item.icon} />)}
                </List>
            </Box>
            {/*
            <Box flexGrow={1} />
            <Box p={2} m={2} bgcolor="background.dark">
                <Typography align="center" gutterBottom variant="h4">
                    Need more?
                </Typography>
                <Typography align="center" variant="body2">
                    Upgrade to PRO version and access 20 more screens
                </Typography>
                <Box display="flex" justifyContent="center" mt={2}>
                    <Button color="primary" component="a" href="https://react-material-kit.devias.io" variant="contained">
                        See PRO version
                    </Button>
                </Box>
            </Box>
            */}
        </Box>
    );

    return (
        <>
            <Hidden lgUp>
                <Drawer anchor="left" classes={{ paper: classes.mobileDrawer }} onClose={onMobileClose} open={openMobile} variant="temporary">
                    {content}
                </Drawer>
            </Hidden>
            <Hidden mdDown>
                <Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
                    {content}
                </Drawer>
            </Hidden>
        </>
    );
};

DashboardNavBar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool
};

DashboardNavBar.defaultProps = {
    onMobileClose: () => {},
    openMobile: false
};

export default DashboardNavBar;