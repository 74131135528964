import React, {Suspense} from 'react';
import {Outlet} from 'react-router-dom';
import {AppBar, CircularProgress, Container, makeStyles, Toolbar, Typography} from '@material-ui/core';
import withAppUserCheckRequests from "../components/ReferencedComponents/withAppUserCheckRequests";
import pingbitHealthLogo from './pingbit-health-logo.png';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: 'white',
    },
    wrapper: {
        [theme.breakpoints.up('sm')]: {
            paddingTop: 64,
        },
        [theme.breakpoints.up('lg')]: {
            paddingLeft: 256
        }
    },
    appBar: {
        color: theme.palette.grey[900],
        backgroundColor: 'rgba(255,255,255,0.35)',
    },
    toolBar: {
        paddingTop: '20px',
        paddingBottom: '20px',
    },
    contentContainer: {
        backgroundColor: 'white',
    },
    content: {},
    footer: {
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
    }
}));

const CleanPageLayout = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.contentContainer}>
                <AppBar position="absolute" className={classes.appBar} elevation={0}>
                    <Container>
                        <Toolbar className={classes.toolBar}>
                            <img src={pingbitHealthLogo} alt="Logo" width="200" height="75"/>
                        </Toolbar>
                    </Container>
                </AppBar>
                <div className={classes.content}>
                    <Suspense fallback={<CircularProgress />}>
                        <Outlet/>
                    </Suspense>
                </div>
                <div className={classes.footer}>
                    <Typography variant="body1" color="textSecondary">Adres: ODTÜ Teknokent</Typography>
                    <Typography variant="body1" color="textSecondary">Galyum Blok BK9 Çankaya - ANKARA</Typography>
                    <Typography variant="body1" color="textSecondary">Telegram @PingBit</Typography>
                    <Typography variant="body1" color="textSecondary">E-posta: info@biyomod.com.tr</Typography>
                    <Typography variant="body1" color="textSecondary">©<strong>PINBGIT</strong> 2022</Typography>
                </div>
            </div>
        </div>
    );
};

export default withAppUserCheckRequests(CleanPageLayout);