import React, {Component} from "react";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import withMessage from "./withMessage";

const withRouter = (Component) => {
    const Wrapper = (props) => {
        const navigate = useNavigate();

        return (
            <Component
                navigate={navigate}
                {...props}
            />
        );
    };

    return Wrapper;
};

const withAppUserCheckRequests = Wrapped => {

    return withRouter(withMessage(class extends Component {


        // Unexpacted error(network down, db down, bug)
        reqInterceptor = axios.interceptors.request.use(req => {
            // console.log(req);
            return req;
        });

        // Expacted errors(Clients)
        resInterceptor = axios.interceptors.response.use(
            res => res,
            error => {
                if (error.response && error.response.status) {
                    switch (error.response.status) {
                        case 401 :
                            this.props.message('Oturum süreniz dolmuştur, Lütfen tekrar giriş yapınız.', 'error');
                            break;
                        default :
                            break;
                    }
                }
                // Do something with response error
                return Promise.reject(error);
            });

        componentWillUnmount() {
            axios.interceptors.request.eject(this.reqInterceptor);
            axios.interceptors.response.eject(this.resInterceptor);
        }

        render() {

            return (
                <Wrapped {...this.props} />
            )
        }
    }));
}

export default withAppUserCheckRequests;