import React from 'react';
import {Navigate} from 'react-router-dom';
import AuthService from "./services/AuthService";
import CleanPageLayout from "./layouts/CleanPageLayout";
import DashboardLayout from "./layouts/DashboardLayout";
import MainLayout from "./layouts/MainLayout";
import AppUserLayout from "./layouts/AppUserLayout";

const NotFound = React.lazy(() => import('./views/NotFound'));
const Logout = React.lazy(() => import('./components/LayoutComponents/Logout'));
const Dashboard = React.lazy(() => import('./views/Dashboard'));
const UserList = React.lazy(() => import('./views/User/UserList'));
const User = React.lazy(() => import('./views/User'));
const Device = React.lazy(() => import('./views/Device'));
const DeviceEdit = React.lazy(() => import('./views/Device/DeviceEdit'));
const DeviceUserEdit = React.lazy(() => import('./views/Device/DeviceUserEdit'));
const DeviceList = React.lazy(() => import('./views/Device/DeviceList'));
const DeviceHistoryList = React.lazy(() => import('views/Device/DeviceHistoryList'));
const Organization = React.lazy(() => import('views/Organization'));
const OrganizationEdit = React.lazy(() => import('./views/Organization/OrganizationEdit'));
const OrganizationList = React.lazy(() => import('./views/Organization/OrganizationList'));
const UserEdit = React.lazy(() => import('./views/User/UserEdit'));
const UserFiles = React.lazy(() => import('./views/User/UserFiles'));
const UserSchedule = React.lazy(() => import('./views/User/UserSchedule'));
const VideoAnalysis = React.lazy(() => import('./views/VideoAnalysis'));
const VideoAnalysisList = React.lazy(() => import('./views/VideoAnalysis/VideoAnalysisList'));
const VideoAnalysisProfile = React.lazy(() => import('./views/VideoAnalysis/VideoAnalysisProfile'));
const VideoAnalysisLog = React.lazy(() => import('./views/VideoAnalysis/VideoAnalysisLog'));
const VideoAnalysisReportV2 = React.lazy(() => import('./views/VideoAnalysis/VideoAnalysisReportV2'));
const Schedule = React.lazy(() => import('./views/Schedule'));
const ScheduleProfile = React.lazy(() => import('./views/Schedule/ScheduleProfile'));
const ScheduleList = React.lazy(() => import('./views/Schedule/ScheduleList'));
const Measurement = React.lazy(() => import('./views/Measurement'));
const MeasurementList = React.lazy(() => import('./views/Measurement/MeasurementList'));
const MeasurementEdit = React.lazy(() => import('./views/Measurement/MeasurementEdit'));
const DeviceTypeList = React.lazy(() => import('views/DeviceType/DeviceTypeList'));
const DeviceType = React.lazy(() => import('views/DeviceType'));
const DeviceTypeEdit = React.lazy(() => import('views/DeviceType/DeviceTypeEdit'));
const MeasurementType = React.lazy(() => import('views/MeasurementType'));
const MeasurementTypeList = React.lazy(() => import('views/MeasurementType/MeasurementTypeList'));
const MeasurementTypeEdit = React.lazy(() => import('views/MeasurementType/MeasurementTypeEdit'));
const ReminderType = React.lazy(() => import('views/ReminderType'));
const ReminderTypeList = React.lazy(() => import('views/ReminderType/ReminderTypeList'));
const ReminderTypeEdit = React.lazy(() => import('views/ReminderType/ReminderTypeEdit'));
const MeasurementDefinition = React.lazy(() => import('views/MeasurementDefinition'));
const MeasurementDefinitionList = React.lazy(() => import('views/MeasurementDefinition/MeasurementDefinitionList'));
const MeasurementDefinitionEdit = React.lazy(() => import('views/MeasurementDefinition/MeasurementDefinitionEdit'));
const PaymentInfo = React.lazy(() => import('views/PaymentInfo'));
const PaymentInfoList = React.lazy(() => import('views/PaymentInfo/PaymentInfoList'));
const PaymentInfoEdit = React.lazy(() => import('views/PaymentInfo/PaymentInfoEdit'));
const RelationType = React.lazy(() => import('views/RelationType'));
const RelationTypeList = React.lazy(() => import('views/RelationType/RelationTypeList'));
const RelationTypeEdit = React.lazy(() => import('views/RelationType/RelationTypeEdit'));
const Relation = React.lazy(() => import('views/Relation'));
const RelationList = React.lazy(() => import('views/Relation/RelationList'));
const RelationEdit = React.lazy(() => import('views/Relation/RelationEdit'));
const Reminder = React.lazy(() => import('views/Reminder'));
const ReminderList = React.lazy(() => import('views/Reminder/ReminderList'));
const ReminderEdit = React.lazy(() => import('views/Reminder/ReminderEdit'));
const Ticket = React.lazy(() => import('views/Ticket'));
const TicketList = React.lazy(() => import('views/Ticket/TicketList'));
const TicketEdit = React.lazy(() => import('views/Ticket/TicketEdit'));
// const RelationUserEdit = React.lazy(() => import('views/Relation/RelationUserEdit'));
const VideoAnalysisRef = React.lazy(() => import('views/VideoAnalysisRef'));
const VideoAnalysisRefList = React.lazy(() => import('views/VideoAnalysisRef/VideoAnalysisRefList'));
const VideoAnalysisRefEdit = React.lazy(() => import('views/VideoAnalysisRef/VideoAnalysisRefEdit'));
const PaymentInfoTransactions = React.lazy(() => import('views/PaymentInfo/PaymentInfoTransactions'));
const Advisor = React.lazy(() => import('views/Advisor'));
const AdvisorList = React.lazy(() => import('views/Advisor/AdvisorList'));
const AdvisorEdit = React.lazy(() => import('views/Advisor/AdvisorEdit'));
const UserGeofence = React.lazy(() => import('views/User/UserGeofence'));
const Location = React.lazy(() => import('views/Location'));
const LocationList = React.lazy(() => import('views/Location/LocationList'));
const DeviceConfiguration = React.lazy(() => import('views/Device/DeviceConfiguration'));
const DeviceAccessList = React.lazy(() => import('views/Device/DeviceAccessList'));
const DeviceStats = React.lazy(() => import('views/Device/DeviceStats'));
const NotificationMessage = React.lazy(() => import('views/NotificationMessage'));
const NotificationMessageList = React.lazy(() => import('views/NotificationMessage/NotificationMessageList'));
const NotificationMessageEdit = React.lazy(() => import('views/NotificationMessage/NotificationMessageEdit'));
const Activity = React.lazy(() => import("views/Activity"));
const ActivityList = React.lazy(() => import("views/Activity/ActivityList"));
const ActivityEdit = React.lazy(() => import("views/Activity/ActivityEdit"));
const UserActivity = React.lazy(() => import("views/UserActivity"));
const UserActivityList = React.lazy(() => import("views/UserActivity/UserActivityList"));
const UserActivityEdit = React.lazy(() => import("views/UserActivity/UserActivityEdit"));
const Form = React.lazy(() => import('views/Form'));
const FormList = React.lazy(() => import('views/Form/FormList'));
const FormEdit = React.lazy(() => import('views/Form/FormEdit'));
const UserForm = React.lazy(() => import('views/UserForm'));
const UserFormList = React.lazy(() => import('views/UserForm/UserFormList'));
const UserFormEdit = React.lazy(() => import('views/UserForm/UserFormEdit'));
const UserSelectSchedule = React.lazy(() => import("./views/SingleUseViews/UserSelectSchedule"));
const Login = React.lazy(() => import("./views/Login"));
const ResetPassword = React.lazy(() => import("./views/ResetPassword"));
const ForgotPassword = React.lazy(() => import("./views/ForgotPassword"));

const SuccessMessage = React.lazy(() => import("./views/SingleUseViews/UserSelectSchedule/success"));
const ErrorMessage = React.lazy(() => import("./views/SingleUseViews/UserSelectSchedule/error"));
const ActivitesEvents = React.lazy(() => import("./views/SingleUseViews/Pages/ActivitesEvents"));

export default function routes(isLoggedIn, role) {
    const isUserOrSuperUser = ['ROLE_USER', 'ROLE_SUPERUSER'].includes(role);
    const isAdmin = role === 'ROLE_ADMIN';

    return ([
        {
            path: 'appuser',
            element: <AppUserLayout/>,
            children: [
                {path: '404', element: <NotFound/>},
                {path: 'success', element: <SuccessMessage/>},
                {path: 'error', element: <ErrorMessage/>},
                {path: 'schedule', element: <UserSelectSchedule/>},
                {path: '*', element: <Navigate to="/appuser/404"/>}
            ]
        },
        {
            path: 'qr',
            element: <CleanPageLayout/>,
            children: [
                {path: '404', element: <NotFound/>},
                {path: 'events', element: <ActivitesEvents/>},
                {path: '*', element: <Navigate to="/qr/404"/>}
            ]
        },
        {
            path: 'app',
            element: isLoggedIn ? <DashboardLayout/> : <Navigate to="/login"/>,
            children: [
                {path: '404', element: <NotFound/>},
                {path: 'logout', element: <Logout/>},
                {path: 'dashboard', element: <Dashboard/>},
                {
                    path: 'users', element: <User/>,
                    children: [
                        {path: 'all', element: <UserList/>},
                        {path: "", element: <UserEdit/>},
                        {path: ":id", element: <UserEdit/>},
                        {path: ":id/files", element: <UserFiles/>},
                        {path: ":id/schedules", element: <UserSchedule/>},
                        {path: ":id/geofences", element: <UserGeofence/>}
                    ]
                },
                {
                    path: 'relations', element: <Relation/>,
                    children: [
                        {path: "all", element: <RelationList/>,},
                        {path: "", element: <RelationEdit/>,},
                        {path: ":id", element: <RelationEdit/>,},
                        // {
                        //     path: "",
                        //     element: AuthService.getRole() === 'ROLE_ADMIN' ? <RelationEdit/> : <RelationUserEdit/>
                        // },
                        // {
                        //     path: ":id",
                        //     element: AuthService.getRole() === 'ROLE_ADMIN' ? <RelationEdit/> :
                        //         <Navigate to="/relations/all"/>
                        // },
                    ]
                },
                !isUserOrSuperUser && {
                    path: 'relationtypes', element: <RelationType/>,
                    children: [
                        {path: "all", element: <RelationTypeList/>,},
                        {path: "", element: <RelationTypeEdit/>},
                        {path: ":id", element: <RelationTypeEdit/>},
                    ]
                },
                !isUserOrSuperUser && {
                    path: 'organizations', element: <Organization/>,
                    children: [
                        {path: "all", element: <OrganizationList/>,},
                        {path: "", element: <OrganizationEdit/>},
                        {path: ":id", element: <OrganizationEdit/>},
                    ]
                },
                !isUserOrSuperUser && {
                    path: 'remindertypes', element: <ReminderType/>,
                    children: [
                        {path: "all", element: <ReminderTypeList/>,},
                        {path: "", element: <ReminderTypeEdit/>},
                        {path: ":id", element: <ReminderTypeEdit/>},
                    ]
                },
                !isUserOrSuperUser && {
                    path: 'forms', element: <Form/>,
                    children: [
                        {path: "all", element: <FormList/>,},
                        {path: "", element: <FormEdit/>},
                        {path: ":id", element: <FormEdit/>},
                    ]
                },
                !isUserOrSuperUser && {
                    path: 'userforms', element: <UserForm/>,
                    children: [
                        {path: "all", element: <UserFormList/>,},
                        {path: "", element: <UserFormEdit/>},
                        {path: ":id", element: <UserFormEdit/>},
                    ]
                },
                {
                    path: 'reminders', element: <Reminder/>,
                    children: [
                        {path: "all", element: <ReminderList/>},
                        {path: "", element: <ReminderEdit/>},
                        {
                            path: ":id",
                            element: <ReminderEdit/>
                        },
                    ]
                },
                !isUserOrSuperUser && {
                    path: 'videoanalyzes', element: <VideoAnalysis/>,
                    children: [
                        {path: "all", element: <VideoAnalysisList/>,},
                        {path: "", element: <VideoAnalysisProfile/>},
                        {path: ":id", element: <VideoAnalysisProfile/>},
                        {path: ":id/log", element: <VideoAnalysisLog/>},
                        // { path: ":id/chart", element: <VideoAnalysisChart />},
                        {path: ":id/report", element: <VideoAnalysisReportV2/>},
                        // { path: ":id/reportV2", element: <VideoAnalysisReportV2 />}
                    ]
                },
                {
                    path: 'schedules', element: <Schedule/>,
                    children: [
                        {path: "all", element: <ScheduleList/>,},
                        {
                            path: "",
                            element: !isUserOrSuperUser ? <ScheduleProfile/> : <Navigate to="/app/schedules/all"/>
                        },
                        {
                            path: ":id",
                            element: !isUserOrSuperUser ? <ScheduleProfile/> : <Navigate to="/app/schedules/all"/>
                        },
                    ]
                },
                !isUserOrSuperUser && {
                    path: 'advisors', element: <Advisor/>,
                    children: [
                        {path: "all", element: <AdvisorList/>,},
                        {path: "", element: <AdvisorEdit/>},
                        {path: ":id", element: <AdvisorEdit/>},
                    ]
                },
                {
                    path: 'measurements', element: <Measurement/>,
                    children: [
                        {path: "all", element: <MeasurementList/>,},
                        {
                            path: "",
                            element: <MeasurementEdit/>
                        },
                        {
                            path: ":id",
                            element: <MeasurementEdit/>
                        },
                    ]
                },
                !isUserOrSuperUser && {
                    path: 'measurementtypes', element: <MeasurementType/>,
                    children: [
                        {path: "all", element: <MeasurementTypeList/>,},
                        {path: "", element: <MeasurementTypeEdit/>},
                        {path: ":id", element: <MeasurementTypeEdit/>},
                    ]
                },
                !isUserOrSuperUser && {
                    path: 'activities', element: <Activity/>,
                    children: [
                        {path: "all", element: <ActivityList/>,},
                        {path: "", element: <ActivityEdit/>},
                        {path: ":id", element: <ActivityEdit/>},
                    ]
                },
                !isUserOrSuperUser && {
                    path: 'useractivities', element: <UserActivity/>,
                    children: [
                        {path: "all", element: <UserActivityList/>,},
                        {path: "", element: <UserActivityEdit/>},
                        {path: ":id", element: <UserActivityEdit/>},
                    ]
                },
                {
                    path: 'measurementdefinitions', element: <MeasurementDefinition/>,
                    children: [
                        {path: "all", element: <MeasurementDefinitionList/>,},
                        {
                            path: "",
                            element: <MeasurementDefinitionEdit/>
                        },
                        {
                            path: ":id",
                            element: <MeasurementDefinitionEdit/>
                        },
                    ]
                },
                !isUserOrSuperUser && {
                    path: 'tickets', element: <Ticket/>,
                    children: [
                        {path: "all", element: <TicketList/>,},
                        {path: "", element: <TicketEdit/>},
                        {path: ":id", element: <TicketEdit/>}
                    ]
                },
                isAdmin && {
                    path: 'devicetypes', element: <DeviceType/>,
                    children: [
                        {path: "all", element: <DeviceTypeList/>},
                        {path: "", element: <DeviceTypeEdit/>},
                        {path: ":id", element: <DeviceTypeEdit/>},
                    ]
                },
                {
                    path: 'userlocations', element: <Location/>,
                    children: [
                        {path: "all", element: <LocationList/>}
                    ]
                },
                {
                    path: 'devices', element: <Device/>,
                    children: [
                        {path: "all", element: <DeviceList/>},
                        {path: "", element: !isUserOrSuperUser ? <DeviceEdit/> : <Navigate to="/app/devices/all"/>},
                        {path: ":id", element: isUserOrSuperUser ? <DeviceUserEdit/> : <DeviceEdit/>},
                        {path: ":id/history", element: <DeviceHistoryList/>},
                        {
                            path: ":id/configuration",
                            element: !isUserOrSuperUser ? <DeviceConfiguration/> : <Navigate to="/app/devices/all"/>
                        },
                        {path: ":id/access", element: <DeviceAccessList/>},
                        {path: ":id/stats", element: <DeviceStats/>},
                    ]
                },
                isAdmin && {
                    path: 'videoanalysisrefs', element: <VideoAnalysisRef/>,
                    children: [
                        {path: "all", element: <VideoAnalysisRefList/>},
                        {path: "", element: <VideoAnalysisRefEdit/>},
                        {path: ":id", element: <VideoAnalysisRefEdit/>},
                    ]
                },
                !isUserOrSuperUser && {
                    path: 'paymentinfos', element: <PaymentInfo/>,
                    children: [
                        {path: "all", element: <PaymentInfoList/>,},
                        {path: "", element: <PaymentInfoEdit/>},
                        {path: ":id", element: <PaymentInfoEdit/>},
                        {path: ":id/transactions", element: <PaymentInfoTransactions/>},
                    ]
                },
                isAdmin && {
                    path: 'notificationmessages', element: <NotificationMessage/>,
                    children: [
                        {path: "all", element: <NotificationMessageList/>,},
                        {path: "", element: <NotificationMessageEdit/>},
                        {path: ":id", element: <NotificationMessageEdit/>},
                    ]
                },
                {
                    path: '/', element: <Navigate
                        to={AuthService.getRole() === 'ROLE_ADMIN' ? "/app/dashboard" : "/app/relations/all"}/>
                },
                {path: '*', element: <Navigate to="/app/404"/>},
            ]
        },
        {
            path: '/',
            element: !isLoggedIn ? <MainLayout/> : <Navigate to="/app"/>,
            children: [
                {path: 'login', element: <Login/>},
                {path: 'resetpassword', element: <ResetPassword/>},
                {path: 'forgotpassword', element: <ForgotPassword/>},
                {path: '404', element: <NotFound/>},
                {path: '/', element: <Navigate to="/login"/>},
                {path: '*', element: <Navigate to="/404"/>}
            ]
        }
    ]);
}
