import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";

import admin_en from "./translations/en-US/admin.json";
import admin_tr from "./translations/tr-TR/admin.json";

import user_en from "./translations/en-US/user.json";
import user_tr from "./translations/tr-TR/user.json";
import AuthService from 'services/AuthService';


i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: AuthService.getLanguage() || 'tr-TR', // language to use
  resources: {
    tr: {
      ROLE_ADMIN: admin_tr,
      ROLE_SUPERUSER: admin_tr,
      ROLE_USER: user_tr,
      ROLE_DOCTOR: user_tr,
      ROLE_NURSE: user_tr,
    },
    en: {
      ROLE_ADMIN: admin_en,
      ROLE_SUPERUSER: admin_en,
      ROLE_USER: user_en,
      ROLE_DOCTOR: user_en,
      ROLE_NURSE: user_en,

    },
  },
});

ReactDOM.render(
  <BrowserRouter basename={process.env.PUBLIC_URL}>
    <I18nextProvider i18n={i18next}>
      <SnackbarProvider maxSnack={10}>
        <App />
      </SnackbarProvider>
    </I18nextProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
