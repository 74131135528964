import React from 'react';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {AppBar, Box, Hidden, IconButton, makeStyles, Toolbar} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import LanguageIcon from '@material-ui/icons/Language';
import InputIcon from '@material-ui/icons/Input';
import Logo from 'components/LayoutComponents/Logo';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import {useTranslation} from "react-i18next";
import {IconFlagTR, IconFlagUS} from 'material-ui-flags';
import AuthService from 'services/AuthService';

const useStyles = makeStyles(() => ({
    root: {},
    avatar: {
        width: 60,
        height: 60
    }
}));

const DashboardTopBar = ({
                             className,
                             onMobileNavOpen,
                             ...rest
                         }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const {t, i18n} = useTranslation(AuthService.getRole());

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChangeLanguage = (lang) => {
        i18n.changeLanguage(lang);
        setAnchorEl(null);
    }

    return (
        <AppBar
            className={clsx(classes.root, className)}
            elevation={0}
            {...rest}
        >
            <Toolbar>
                <RouterLink to="/">
                    <Logo/>
                </RouterLink>
                <Box flexGrow={1}/>
                <Hidden mdDown>
                    {/*<IconButton color="inherit">*/}
                    {/*  <Badge badgeContent={notifications.length} color="primary" variant="dot">*/}
                    {/*    <NotificationsIcon />*/}
                    {/*  </Badge>*/}
                    {/*</IconButton>*/}
                    <IconButton
                        aria-label="languages"
                        aria-controls="languages-menu"
                        aria-haspopup="true"
                        onClick={handleMenu}
                        color="inherit"
                    >
                        <LanguageIcon/>
                    </IconButton>
                    <Menu
                        id="languages-menu"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={open}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={() => handleChangeLanguage('tr-TR')}>
                            <IconFlagTR/>&nbsp;{t('languages.tr-TR')}
                        </MenuItem>
                        <MenuItem onClick={() => handleChangeLanguage('en-US')}>
                            <IconFlagUS/>&nbsp;{t('languages.en-US')}
                        </MenuItem>
                    </Menu>

                    <IconButton color="inherit" onClick={() => navigate("/app/logout", {replace: true})}>
                        <InputIcon/>
                    </IconButton>
                </Hidden>
                <Hidden lgUp>
                    <IconButton color="inherit" onClick={onMobileNavOpen}>
                        <MenuIcon/>
                    </IconButton>
                </Hidden>
            </Toolbar>
        </AppBar>
    );
};

DashboardTopBar.propTypes = {
    className: PropTypes.string,
    onMobileNavOpen: PropTypes.func
};

export default DashboardTopBar;