import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import {useTranslation} from "react-i18next";
import AuthService from 'services/AuthService';

const useStyles = makeStyles(() => ({
    firstText: {
        color: '#FFFFFF',
    },
    secondText: {
        color: '#CDCDCD',
    }
}));

const Logo = () => {
  const classes = useStyles();
  const {t} = useTranslation(AuthService.getRole());
  //<img alt="Logo" src="/static/logo.svg" {...props}/>
  return (
    <Typography className={classes.firstText} variant="h2">PB<span className={classes.secondText}>{t('app.title')}</span></Typography>
  );
};

export default Logo;